<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 500],
        }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        :server-items-length.sync="total"
        @update:options="getItems"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Survey responses</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <a
              v-if="finnishedRefreshing"
              :href="`${baseUrl}/api/export-xls/${$route.params.id}/${selectedWebsite || 'null'}/${selectedProgramme || 'null'}/${selectedMediaType || 'null'}/${fromDate || 'null'}/${toDate || 'null'}`"
              style="background-color: lightgreen; color: white; border-radius: 5px; padding: 5px; text-decoration: none; cursor: pointer"
            >
              EXPORT TO XML
            </a>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div style="display: flex">
            <v-select
              :items="websites"
              v-model="selectedWebsite"
              item-text="domain_data.title"
              item-value="id"
              label="Website"
              persistent-hint
              style="margin-right: 10px; margin-left: 10px"
            ></v-select>
            <v-select
              :items="programmes"
              v-model="selectedProgramme"
              item-text="title"
              item-value="title"
              label="Program"
              persistent-hint
              style="margin-right: 10px; margin-left: 10px; max-width: 200px"
            ></v-select>
            <v-select
              :items="['vimeo','audio','photo']"
              v-model="selectedMediaType"
              label="Media type"
              persistent-hint
              style="margin-right: 10px; margin-left: 10px"
            ></v-select>
            <v-dialog
              ref="dialogStartDate"
              v-model="modalStartDate"
              :return-value.sync="startDate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  style="margin-right: 10px; margin-left: 10px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                locale="sr-Latn"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="saveStartDate">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="dialogEndDate"
              v-model="modalEndDate"
              :return-value.sync="endDate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  style="margin-right: 10px; margin-left: 10px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                locale="sr-Latn"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="saveEndDate">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary">No data</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { fetchSurveyAnswers, fetchWebsites, fetchProgrammesDropdown } from "@/services";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "SurveyAnswers",
  components: {
    TheSidebar,
  },
  data() {
    return {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      perPage: 10,
      sortDirection: true,
      page: 1,
      total: 0,
      sortBy: "created_at",
      baseUrl: window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz' : window.location.origin,
      spinnerActive: false,
      headers: [
        { text: "Website", value: "website_name" },
        { text: "Program", value: "program" },
        { text: "Survey name", value: "survey_name" },
        { text: "Survey type", value: "survey_type" },
        { text: "Media type", value: "media_type" },
        { text: "Created at", value: "created_at" },
        { text: "Question", value: "question" },
        { text: "Answer", value: "answer" },
        { text: "First name", value: "first_name" },
        { text: "Last name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Specialization", value: "specialist_1" },
      ],
      modalStartDate: false,
      modalEndDate: false,
      fromDate: null,
      toDate: null,
      finnishedRefreshing: true,
      items: [],
      websites: [],
      selectedWebsite: null,
      programmes: [],
      selectedProgramme: null,
      selectedMediaType: null,
      editedIndex: -1,
      editedItem: { website_config: [{ value: "" }, { value: "" }] },
      defaultItem: { website_config: [{ value: "" }, { value: "" }] },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    };
  },
  computed: {
    formTitle() {
      return "New Item ??";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    selectedWebsite() {
      this.getItems()
    },
    fromDate() {
      this.getItems()
    },
    toDate() {
      this.getItems()
    },
    selectedProgramme() {
      this.getItems()
    },
    selectedMediaType() {
      this.getItems()
    }
  },
  mounted() {
    this.getItems()
    this.getWebsites()
    this.getProgrammes()
  },
  methods: {
    saveStartDate() {
      this.$refs.dialogStartDate.save(this.fromDate);
    },
    saveEndDate() {
      this.$refs.dialogEndDate.save(this.toDate);
    },
    async getWebsites() {
      await fetchWebsites().then(response => {
        this.websites = response.data
      })
    },
    async getProgrammes() {
      await fetchProgrammesDropdown().then(response => {
        this.programmes = response.data.data
      })
    },
    async getItems() {
      let tableData = {}
      this.finnishedRefreshing = false
      tableData["perPage"] = this.perPage
      tableData["sortBy"] = this.sortBy
      tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC"
      tableData["page"] = this.page
      tableData["survey_id"] = this.$route.params.id
      tableData["website_id"] = this.selectedWebsite
      tableData["program_name"] = this.selectedProgramme
      tableData["media_type"] = this.selectedMediaType
      tableData["duration_from"] = this.fromDate
      tableData["duration_to"] = this.toDate
      this.spinnerActive = true
      await fetchSurveyAnswers(tableData).then(res => {
        if (res.data) {
          this.items = res.data.data
          this.spinnerActive = false
          this.total = res.data.total
          this.finnishedRefreshing = true
        }
      }).catch((error) => {
        console.log(error)
        this.spinnerActive = false
      })
    }
  }
};
</script>
